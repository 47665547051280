import React, { CSSProperties } from "react";
import Banner from "../../Components/banner/Banner";
import ReactPlayer from "react-player";
import "./homePage.css";
import Logo from "../../Components/logo/Logo";
import arrow from "../../assets/picto/Arrow.svg";
import arrow6 from "../../assets/picto/arrow6.svg";
import Vid from "../../assets/showreel_polydea.mp4";
import partenaires from "../../assets/picto/partenaires.svg";
import partn1 from "../../assets/picto/partn1.svg";
import partn2 from "../../assets/picto/partn2.svg";
import partn3 from "../../assets/picto/partn3.svg";
import partn4 from "../../assets/picto/partn4.svg";
import partn5 from "../../assets/picto/partn5.svg";
import partn6 from "../../assets/picto/partn6.svg";
import partn7 from "../../assets/picto/partn7.svg";
import partn8 from "../../assets/picto/partn8.svg";
import partn9 from "../../assets/picto/partn9.svg";
import partn10 from "../../assets/picto/partn10.svg";
import partn11 from "../../assets/picto/partn11.svg";
import partn12 from "../../assets/picto/partn12.svg";
import showreel_label from "../../assets/pictures/showreel_label.png";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation(["translation"]);

  // style for banner
  const style: { [key: string]: CSSProperties } = {
    span: {
      fontFamily: "sen-extrabold",
      fontWeight: "800",
      fontSize: "43px",
      lineHeight: "69px",
    },
  };

  const onClickedButton = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    let hero = document.getElementById("showreel");
    e.preventDefault();
    hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <section className="title-section">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Polydea - Accueil</title>
          <meta
            name="description"
            content="Polydea est une agence de communication internationale spécialisée dans le développement (C4D)"
          />
          <link rel="canonical" href="https://www.polydea.com/" />
        </Helmet>
        <span className="spot-top-pastelPink"></span>
        <span className="spot-top-pastelGreen"></span>
        <span className="spot-top-pastelYellow"></span>
        <span className="spot-middle-pastelpink"></span>
        <span className="spot-bottom-pastelyellow"></span>
        <span className="spot-bottom-pastelyellow-2"></span>
        <Logo />
        <p className="desc">{t("homeText", { ns: ["translation"] })}</p>
        <a
          href="/"
          onClick={(e) => {
            onClickedButton(e);
          }}
          className="showreel-button"
        >
          <img src={arrow6} alt="arrow6" />
        </a>
      </section>
      <section id="showreel">
        <Banner
          bgColor={"linear-gradient(92.92deg, #FF82F2 -15.93%, #AF94FB 93.86%)"}
        >
          <h2 className="title-banner">
            <span style={style.span}>01</span>
            SHOWREEL
          </h2>
        </Banner>
        <article className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={Vid}
            playing={true}
            controls={true}
            loop={true}
            playsinline={true}
            muted={true}
            volume={0.5}
            width="100%"
          />
        </article>
      </section>

      <section className="partners-group">
        <div className="partners-container">
          <article className="cube whithe">
            <a
              href={"https://www.urban-initiative.eu/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn1} alt="partn1" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={"https://www.urban-initiative.eu/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn2} alt="partn2" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={
                "https://www.safe-europe.eu/news-posts/eulead-eu-libya-expertise-advisory-and-deployment-project-fact-sheet/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn3} alt="partn3" />
            </a>
          </article>
          <article className="cube white">
            <a
              href={"https://www.mofed.gov.et/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn4} alt="partn4" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={"https://parfid.fr/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn5} alt="partn5" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={"https://www.expertisefrance.fr/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn6} alt="partn6" />
            </a>
          </article>
          <article className="cube white">
            <a
              href={"https://www.cledjibouti.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn7} alt="partn7" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={"https://www.itu.int/fr/Pages/default.aspx"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn8} alt="partn8" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={
                "https://www.afd.fr/fr/sunref-un-label-finance-verte-du-groupe-afd"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn9} alt="partn9" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={"https://www.nweurope.eu/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn10} alt="partn10" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={"https://unwomenusa.org/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn11} alt="partn11" />
            </a>
          </article>
          <article className="cube whithe">
            <a
              href={"https://www.undp.org/fr"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partn12} alt="partn12" />
            </a>
          </article>
        </div>
      </section>
    </>
  );
};

export default Home;
