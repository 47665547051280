import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';


const CardDetails = ({ project } : {  project : any }) => {
  
  const [projectInfos, setProjectInfos] = useState(project);
  const params = useParams();

  useEffect(() => {
    const id = params.id;
      axios
          .get(`http://localhost:3000/api/projet/${id}`)
          .then((res) => res.data)
          .then((data) => setProjectInfos(data))
  },   [params.id]

  )


  return (
    <div>
      <div>HELLO WORLD</div>
        <div>
        {/* <h2>{project.name}</h2> */}
        {/* <p>{details.desc}</p>
        <img className='project-img' src={details.image} alt={details.name} /> */}
        </div>
   </div>
  )
      }

export default CardDetails