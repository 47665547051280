import React from "react";
import Contact from "../../Components/contact/Contact";
import "./ContactPage.css";
import { Helmet } from "react-helmet";

const ContactPage: React.FC = () => {
  return (
    <div className="contact-container page-body">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Polydea</title>
        <meta name="description" content="Veuillez nous contactez ici !"/>
        <link rel="canonical" href="https://polydea.com/contact" />
      </Helmet>
      <h1 className="animated">Contact</h1>
      <div className="contact-body">
        <Contact />
        <div className="color-text-container">
          <div className="contact-spot-pink"></div>
          <div className="contact-spot-yellow"></div>
          <div className="contact-spot-green"></div>
          <div className="contact-text">
            <h2 className="contact-text-title">POLYDEA PARIS</h2>
            <p className="contact-info">
              8 RUE DES LYONNAIS <br />
              75005 PARIS <br />
              contact@polydea.com <br />
              +33 1.45.35.72.50
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
