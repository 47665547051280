import { Outlet, NavLink } from "react-router-dom";
import './header.css';
import logo from '../../assets/picto/logo_horizon.png';
import { useState } from "react";
import TranslateBtn from "../translation/TranslateBtn";
import { useTranslation } from 'react-i18next';

const Headers = () => {
    const [onActive, setOnActive] = useState(false);

      const { t } = useTranslation(['translation']);

    let OncloseOverlay = ():void => {
        setTimeout(()=>{setOnActive(false) }, 300)
    }

    return (
        <header>
                <NavLink to="/">
                    <img className="logo-polydea" src={logo} alt="logo polydea" /> 
                </NavLink>


            <div className={(onActive ? "button_container active" : "button_container" )} onClick={() => { setOnActive(!onActive); }} id="toggle">
                <span className="top"></span>
                <span className="middle"></span>
                <span className="bottom"></span>
            </div>
            <div className="langBtnContainer">
            <TranslateBtn />
            </div>
            <div className={(onActive ? "overlay open" : "overlay" )} onClick={()=>{OncloseOverlay()}}  id="overlay">
                <nav className="overlay-menu">
                    <ul>
                        <li>
                            <NavLink className={({ isActive }) =>
                                (isActive ? "nav-link-active nav-link" : "nav-link")} to="/">Polydea</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) =>
                                (isActive ? "nav-link-active nav-link" : "nav-link")} to="/expertise">Expertise</NavLink>
                        </li>
                        {/* <li>
                        <NavLink className={({ isActive }) =>
                                (isActive ? "nav-link-active nav-link" : "nav-link")} to="/services">Services</NavLink>
                        </li> */}
                        {/* <li>
                        <NavLink className={({ isActive }) =>
                                (isActive ? "nav-link-active nav-link" : "nav-link")} to="/projets">Projets</NavLink>
                        </li> */}
                        <li>
                            <NavLink className={({ isActive }) =>
                                (isActive ? "nav-link-active nav-link" : "nav-link")} to="/equipe">{t('teamHeader', {ns: ['translation']})}</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) =>
                                (isActive ? "nav-link-active nav-link" : "nav-link")} to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            <Outlet />
        </header>
    )
};



export default Headers;


