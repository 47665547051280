/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState } from 'react';
import './Product.css';


const Product = ({ product, togglePopup } : { props: any, product: any, togglePopup: any}) => {

  interface iproducts {
    [x: string]: any;
    name: string,
    text: string,
    styles?: React.CSSProperties
    // [key: string]: string;
  }  


    // const [products, setProducts] = useState<iproducts | null>(null);
    // const [isTextOpen, setIsTextOpen] = useState(false);  
    
    
      const products = [
              {
              id: 1,
              name: "Tous",
              // text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              },
              {
              id: 2,
              name: "Stratégie",
              // text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              },
             {
              id: 3,
              name: "Design Graphique",
              // text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
            },
            {
              id: 4,
              name: "Motion Design",
              // text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
            },
            {
            id: 5,
            name: "Médias sociaux",
            // text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
            },
            {
            id: 6,
            name: "Print",
            // text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
            },
            {
            id: 7,
            name: "Web",
            // text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
            },
      ]

      


  return (
    <div className='categories-list-container'>
      {
        products &&
        products.map((product: any) =>
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
           <div key={product.id} className={product.name.split(" ").join("-") +' product-element' } onClick={(e) => togglePopup(e)}> 
            <div className={product.name.split(" ").join("-") + " product-card "}  >
              <h3 className={product.name.split(" ").join("-") + "Tous product-title"}>{product.name}</h3>
              {/* <p>{product.text}</p> */} 
              </div>
            </div>
        )}
    </div>
  )
  }


export default Product