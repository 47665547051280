import React from "react";
// import ReactGa from "react-ga";
import ReactGA from "react-ga4";
// #useLocation used insideaBroser Router
import { useLocation } from "react-router";
// #We are receiving the initialized prop created by useAnalytics()
// #and children props.(All routes provided by Browser Router)
interface WrapperProps {
  initialized: boolean;
  children: React.PropsWithChildren<any>;
}
export function Wrapper(props: WrapperProps) {
  const location = useLocation();

  React.useEffect(() => {
    // #If React GA initialized, now we are sending pageviews
    // #to our Google Analytics when location changes
    if (props.initialized) {
      ReactGA.initialize(window.location.pathname + window.location.search);
    }
  }, [props.initialized, location]);
  // #Here we return the children involved by it's wrapper
  return props.children;
}
