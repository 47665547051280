import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Facebook from "../../assets/picto/icone_RS_facebook.svg";
import Behance from "../../assets/picto/icone_RS_behance.svg";
import Insta from "../../assets/picto/icone_RS_insta.svg";
import Linkedin from "../../assets/picto/icone_RS_linkedin.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation(["translation"]);

  return (
    <div className="main-container-footer">
      <footer className="footer-container">
        <section className="footer-left-side">
          <div className="social">
            <a
              className="social-networks"
              href="https://www.behance.net/elsaberry"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Behance} alt="icône Behance" />
            </a>
            <a
              className="social-networks"
              href="https://www.linkedin.com/company/polydea/?originalSubdomain=fr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} alt="icône LinkedIn" />
            </a>
            <a
              className="social-networks"
              href="https://www.instagram.com/polydea_eu/?hl=fr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Insta} alt="icône Instagram" />
            </a>
            <a
              className="social-networks"
              href="https://www.facebook.com/polydea/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} alt="icône Facebook" />
            </a>
          </div>
          <section className="legal-contact">
            <div className="legal-section">
              <div className="legal-notice">
              <Link to={{ pathname: "/mentions-legales" }}>
                  {t("legalNotice", { ns: ["translation"] })}
                </Link>
              </div>
              <div className="data-protection">
              <Link to={{ pathname: "/politique-protection-des-donnees" }}>
                  {t("dataProtectionPolicy", { ns: ["translation"] })}
                </Link>
              </div>
            </div>
            <div className="contact-section">
              <a
                className="footer-link"
                href="/contact"
                target="_blank"
                rel="noreferrer"
              >
                Contact
              </a>
            </div>
          </section>
        </section>
        <section className="footer-right-side">
          <div className="copyright">
            <p>2022©</p>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Footer;
