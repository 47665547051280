import React, { useState } from "react";
import i18n from "../../i18n/i18n";
import './TranslateBtn.css';

const TranslateBtn = () => {
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [language, setLanguage] = useState('Fr');
  const [color, setColor] = useState("white");

  // const handleColor = () => {
  //   setColor({isActive => !isActive});
  // };

// const handleClr = 
  return (
    <div className="btnContainer">
      <button className= {
                      language === "En"
                      ? "lang-active-btn-en"
                      : "lang-non-active-btn-en"
                  }
     onClick={() => {changeLanguage("en") ; setLanguage("En")}}
    >En</button>
      <button className= {
                              language === "Fr"
                              ? "lang-active-btn-fr"
                              : "lang-non-active-btn-fr"
                          }
             onClick={() => {changeLanguage("fr") ; setLanguage("Fr")}}
      >Fr</button>
    </div>
  );
};

export default TranslateBtn;
