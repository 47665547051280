//  useAnalytics
import ReactGA from "react-ga4";

export function UseAnalytics(id: string) {
  // Verify if Google Analytics was initialized
  let initialized: boolean = true;
  // If enviromnent is localhost, we initialize Analytics
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id);
    initialized = true;
  }
  if (process.env.NODE_ENV === "development") {
    ReactGA.initialize(id);
    initialized = true;
  }
  ReactGA.initialize(id);
  initialized = true;
  //Here, we are returning the initialized state to be used after
  return {
    initialized,
  };
}
