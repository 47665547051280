import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import ReactGa from "react-ga4";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation(["translation"]);

  const defaultInputs = {
    email: "",
    object: "",
    message: "",
  };

  const placeholderObject = t("objectPlaceholder", { ns: ["translation"] });
  const placeholderMessage = t("messagePlaceholder", { ns: ["translation"] });
  const submitBtn = t("submitBtn", { ns: ["translation"] });

  const [inputs, setInputs] = useState(defaultInputs);
  const { email, object, message } = inputs;

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ReactGa.event({
      category: `contact Page`,
      action: "contact from send",
      label: `contact Page form submit`,
    });
    console.log(inputs);
    alert("Merci pour votre message.");

    // sendForm will automatically collect the values of the form and pass them to the specified template. The form should have a distinct ID, and the name attribute of each field should correspond to the variable name used in the template.
    emailjs
      .sendForm(
        "service_yg1ypwn",
        "template_31w6ioh",
        "#myForm",
        "U1y5yJ7JMpynhxNY8" //(optional) Public Key of the account. It’s not required if you used the init method
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    // The method returns the Promise. Where response is object what contains the status and the text properties.

    setInputs(defaultInputs);
  };

  return (
    <div className="form-container">
      <form id="myForm" onSubmit={onSubmit}>
        <label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="EMAIL"
            value={inputs.email || ""}
            onChange={handleChange}
          />
        </label>
        <label>
          <input
            type="text"
            id="object"
            name="object"
            placeholder={placeholderObject}
            value={inputs.object || ""}
            onChange={handleChange}
          />
        </label>
        <label>
          <textarea
            id="message"
            name="message"
            placeholder={placeholderMessage}
            value={inputs.message || ""}
            onChange={handleChange}
          />
        </label>
        <div className="submit-btn">
          <input value={submitBtn} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default Contact;
