import React from 'react';
import Team_4 from '../../assets/pictures/Team_4.png';
import './Team.css';

const Team = () => {
  return (
    <div className='team-img-container'>
      <img src={Team_4} alt="l'équipe Polydea" className='team-img' />
    </div>
  )
}

export default Team