import React from "react";
import { useTranslation } from "react-i18next";
import './LegalNotices.css';

const LegalNotices = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <div className="legalNoticeContainer">
      <article className="legalNoticesHeader">
        <h1>{t("legalNoticesTitle", { ns: ["translation"] })}</h1>
        <p>{t("legalNoticesSubTextPreLink", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalNoticesSubTextPostLink", { ns: ["translation"] })}
        </p>
      </article>
      <article>
        <h3>{t("legalDatas", { ns: ["translation"] })} </h3>
        <p>
          {t("legalDatasBloc1", { ns: ["translation"] })} 
          <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
          {t("legalDatasBloc1Bis", { ns: ["translation"] })}
            <br /> <br />
          {t("legalDatasBloc2", { ns: ["translation"] })} <br /> <br />
          {t("legalDatasBloc3", { ns: ["translation"] })} <br />
          {t("legalDatasBloc4", { ns: ["translation"] })} <br /> <br />
          {t("legalDatasBloc5", { ns: ["translation"] })} <br />
          {t("legalDatasBloc6", { ns: ["translation"] })} <br /> <br />
          {t("legalDatasBloc7", { ns: ["translation"] })} <br />
          {t("legalDatasBloc8", { ns: ["translation"] })} <br />
          {t("legalDatasBloc9", { ns: ["translation"] })} <br /> <br />
          {t("legalDatasBloc10", { ns: ["translation"] })} <br />
          {t("legalDatasBloc11", { ns: ["translation"] })} <br /> <br />
          {t("legalDatasBloc12", { ns: ["translation"] })} <br /> 
          {t("legalDatasBloc13", { ns: ["translation"] })} <br /> 
          {t("legalDatasBloc14", { ns: ["translation"] })} <br /> 
          {t("legalDatasBloc15", { ns: ["translation"] })} <br /> 
          {t("legalDatasBloc16", { ns: ["translation"] })} <br /> 
          {t("legalDatasBloc17", { ns: ["translation"] })} <br /> <br />
          {t("legalDatasBloc18", { ns: ["translation"] })}
          <a
            href="https://www.generateur-de-mentions-legales.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            site.
          </a>
          <br />
        </p>
      </article>
      <article>
        <h3>{t("legalPresentation", { ns: ["translation"] })} </h3>
        <p>
          {t("legalPresentationPreLink", { ns: ["translation"] })}
          <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
          {t("legalPresentationPostLink", { ns: ["translation"] })}
          <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
          {t("legalPresentationPostLink2", { ns: ["translation"] })}
          <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
          {t("legalPresentationPostLink3", { ns: ["translation"] })}
        </p>
      </article>
      <article>
        <h3>{t("legalAccessibilityTitle", { ns: ["translation"] })} </h3>
        <p>{t("legalAccessibilityText", { ns: ["translation"] })}</p>
      </article>
      <article>
        <h3>{t("legalIntellectPropertyTitle", { ns: ["translation"] })} </h3>
        <p>{t("legalIntellectPropertyText1", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalIntellectPropertyText2", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalIntellectPropertyText3", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalIntellectPropertyText4", { ns: ["translation"] })}

        </p>
      </article>
      <article>
        <h3>{t("legalCookiesTitle", { ns: ["translation"] })} </h3>
        <p>{t("legalCookiesText1", { ns: ["translation"] })} 
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalCookiesText2", { ns: ["translation"] })}
        <br /> <br />
        {t("legalCookiesText3", { ns: ["translation"] })}
        </p>
      </article>
      <article>
        <h3>{t("legalPersonalDataTitle", { ns: ["translation"] })} </h3>
        <p>
        {t("legalPersonalDataText1", { ns: ["translation"] })} <br /> <br />
        {t("legalPersonalDataText2", { ns: ["translation"] })} 
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalPersonalDataText3", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalPersonalDataText4", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalPersonalDataText5", { ns: ["translation"] })}
        <br /> <br />
        {t("legalPersonalDataText6", { ns: ["translation"] })} 
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalPersonalDataText7", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalPersonalDataText8", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalPersonalDataText9", { ns: ["translation"] })}
        <a
            href="https://www.polydea.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            polydea.com
          </a>
        {t("legalPersonalDataText10", { ns: ["translation"] })}
        <br /> <br />
        {t("legalPersonalDataText11", { ns: ["translation"] })} 
        </p>
      </article>

    </div>
  );
};

export default LegalNotices;
