import React from 'react';
import "./Error404.css";

const Error404 = () => {
    return (
        <div className='error'>
            404 page not found
        </div>
    );
};
 
export default Error404;