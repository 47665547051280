import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import translationFR from './fr/translation.json';

const resources = {
  en: {
    translation: translationEN, 
  },
  fr: {
    translation: translationFR,
  }
};

i18next
.use(initReactI18next)
.init({
  lng: 'fr', // if you're using a language detector, do not define the lng option
  debug: true,
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  resources
  }
);

export default i18next