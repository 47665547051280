import React from 'react';
import { useTranslation } from 'react-i18next';
import './Expertise.css';

const Expertise = ({ expertise, togglePopup }: { expertise: any, togglePopup: any }) => {

  interface expertises {
    name: string,
    text: string,
    [key: string]: string;
  }

  const { t, i18n } = useTranslation(['translation']);


  const expertises = [
    {
      name: t('strategyName', {ns: ['translation']}),
      text: t('strategyDesc', {ns: ['translation']}),
    },
    {
      name: t('graphicIdName', {ns: ['translation']}),
      text: t('graphicIdDesc', {ns: ['translation']}),
    },
    {
      name: t('motionName', {ns: ['translation']}),
      text: t('motionDesc', {ns: ['translation']}),
    },
    {
      name: t('campaignName', {ns: ['translation']}),
      text: t('campaignDesc', {ns: ['translation']}),
    },
    {
      name: t('socialMediaName', {ns: ['translation']}),
      text: t('socialMediaDesc', {ns: ['translation']}),
    },
    {
      name: t('webName', {ns: ['translation']}),
      text: t('webDesc', {ns: ['translation']}),
    },
    {
      name: t('communicationName', {ns: ['translation']}),
      text: t('communicationDesc', {ns: ['translation']}),
    },
    {
      name: t('magazineName', {ns: ['translation']}),
      text: t('magazineDesc', {ns: ['translation']}),
    },
  ]


  return (
    <div className='expertise-list-container'>
      {
        expertises &&
        expertises.map((expertise: any) =>
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
             <div className={expertise.name.split(" ").join("-") +' list-element '} >
           {/* <div className={expertise.name.split(" ").join("-") +' list-element '} onClick={(e) => togglePopup(e)}> */}
            <div className={expertise.name.split(" ").join("-") + " card "}  >
              <h3 className={expertise.name.split(" ").join("-") + " element-title "}>{expertise.name}</h3>
              <p>{expertise.text}</p>
            </div>
          </div>
        )}
    </div>
  )
}

export default Expertise