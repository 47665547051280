import React, { useEffect, useState } from 'react';
import { useParams } from'react-router-dom';
import './ProjectList.css';
import CardProject from '../cards/CardProject';


const ProjectList = ({ data, product } : { data: any, product: any}) => {

    // const [ projects, setProject] = useState([]);
    
    return (
        <div className='Project'>
            <div className='cards-container'>
                {
                    data &&
                    data
                    .map((project: any) =>
                        <div key={project.id}> 
                           <CardProject project={project} data={data} projectPage={undefined} />
                        </div>
                )}
            </div>
        </div>
    )
}

export default ProjectList