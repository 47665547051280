import React from 'react';
import './banner.css'

const Banner = ({ bgColor, ...props }: { children: JSX.Element, bgColor: string }) => {

    return (
        <div className='banner' style={{ 'background': bgColor }}>
            {props.children}
        </div>
    );
};

export default Banner;