/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CardDetails from "./CardDetails";
// import CardsDetails from './CardsDetails';
import "./CardProject.css";

const CardProject = ({
  project,
  projectPage,
  data,
}: {
  project: any;
  projectPage: any;
  data: any;
}) => {
  <meta name="description" content="Cet espace est en cours de maintenance" />;
  return (
    <div className="Project">
      <div className="project-card">
        <div>
          {/* <Link to={{ pathname: `/projet/${project.id }` }}> */}
          <img
            className="project-img"
            src={project.image[0].image}
            alt={project.name}
          />
          <div
            className={
              project.category.includes("Stratégie")
                ? "strat-active-dash dash-category"
                : "non-active-dash"
            }
          ></div>
          <div
            className={
              project.category.includes("Design Graphique")
                ? "design-active-dash dash-category"
                : "non-active-dash"
            }
          ></div>
          <div
            className={
              project.category.includes("Motion Design")
                ? "motion-active-dash dash-category"
                : "non-active-dash"
            }
          ></div>
          <div
            className={
              project.category.includes("Médias sociaux")
                ? "medias-active-dash dash-category"
                : "non-active-dash"
            }
          ></div>
          <div
            className={
              project.category.includes("Print")
                ? "print-active-dash dash-category"
                : "non-active-dash"
            }
          ></div>
          <div
            className={
              project.category.includes("Web")
                ? "web-active-dash dash-category"
                : "non-active-dash"
            }
          >
            {/* <h3 className='project-title'>{project.name}</h3>
          <p className='project-date'>{project.date}</p> */}
          </div>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default CardProject;
