import React, { useState } from 'react'
import { DiagnosticCategory } from 'typescript';
import PopUp from '../../Components/popup/PopUp';
import Product from '../../Components/products/Product';
import CardProject from '../../Components/projects/cards/CardProject';
import AllProjects from '../../Components/projects/list/AllProjects';
import ProjectList from '../../Components/projects/list/ProjectList';
import './ProductPage.css';


const ProductsPage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<iData | null>(null);
  const [isProjectActive, setIsProjectActive] = useState(true);

  
  interface iData {
    category: string,
    // text: string,
    styles?: React.CSSProperties,
    data: Array<iArray>
  }

  interface iArray {
    id: number,
    category: string,
    name: string,
    date: string,
    desc: string,
    image: Array<iImages>,
    behance: any,
  }
  interface iImages {
    image: string,
  }

  const allProjects = [
      {
        id: 1,
        category: "Stratégie Tous",
        name: "Stratégie 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
                      behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 2,
        category: "Stratégie Tous",
        name: "Stratégie 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
                      behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {

        id: 3,
        category: "Test Tous",
        name: "Stratégie 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 4,
        category: "Web Tous",
        name: "Test",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 5,
        category: "Design Graphique Tous",
        name: "Identité Graphique 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 6,
        category: "Design Graphique Tous",
        name: "Identité Graphique 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 7,
        category: "Design Graphique Tous",
        name: "Identité Graphique 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 8,
        category: "Design Graphique Tous",
        name: "Identité Graphique 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 9,
        category: "Design Graphique Tous",
        name: "Identité Graphique 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 10,
        category: "Design Graphique Tous",
        name: "Identité Graphique 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 15,
        category: "Motion Design Tous",
        name: "Motion Design 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 16,
        category: "Motion Design Tous",
        name: "Motion Design 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 17,
        category: "Motion Design Tous",
        name: "Motion Design 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {                        
        id: 18,
        category: "Médias sociaux Tous",
        name: "Médias sociaux 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 19,
        category: "Médias sociaux Tous",
        name: "Médias sociaux 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 21,
        category: "Médias sociaux Tous",
        name: "Médias sociaux 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 22,
        category: "Médias sociaux Tous",
        name: "Médias sociaux 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 23,
        category: "Print Tous",
        name: "Rapports & magazines 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 24,
        category: "Print Tous",
        name: "Rapports & magazines 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',

      },
      {
        id: 25,
        category: "Print Tous",
        name: "Rapports & magazines 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 28,
        category: "Web Tous",
        name: "Sites internet 1",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      {
        id: 29,
        category: "Web Tous",
        name: "Sites internet 2",
        date: "2021-2022",
        desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
        image: [
          {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        },
        {
          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
          {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },                    
      ],
        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      },
      // {
      //   id: 3,
      //   category: "Web",
      //   name: "Sites internet 1",
      //   date: "2021-2022",
      //   desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
      //   image: [
      //     {
      //     image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      //   },
      //   {
      //   image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      //   },
      //   {
      //     image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      //     },
      //     {
      //       image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      //       },
      //       {
      //         image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      //         },
      //         {
      //           image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      //           },                    
      // ],
      //   behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
      // },
    ]



  const togglePopup = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

    setIsOpen(!isOpen);


    if ((e.target as Element).classList.contains('Stratégie')) {
      console.log('Stratégie');
      setData(
        {
          category:  "Stratégie", 
          data: [
            {
              id: 1,
              category: "Stratégie ",
              name: "Stratégie 1",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            },
            {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },                    
          ],
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 2,
              category: "Stratégie",
              name: "Stratégie 2",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 3,
              category: "Stratégie",
              name: "Stratégie 1",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            }
          ]
        })
      }

    else if ((e.target as Element).classList.contains('Design-Graphique')) {

      setData(
        {
          category: "Design Graphique", 
          data: [
            {
              id: 1,
              category: "Design Graphique",
              name: "Identité Graphique 1",
              date: "2021-2022",
              desc:"Polydea met en place des identités graphiques cohérentes et percutantes pour accompagner vos projets de développement",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 2,
              category: "Design Graphique",
              name: "Identité Graphique 2",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 3,
              category: "Design Graphique",
              name: "Identité Graphique 1",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 4,
              category: "Design Graphique",
              name: "Identité Graphique 2",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 5,
              category: "Design Graphique",
              name: "Identité Graphique 1",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
          ]
        })
    }
    else if ((e.target as Element).classList.contains('Motion-Design')) {

      setData(
        {
          category: "Motion Design", 
          data: [
            {
              id: 1,
              category: "Motion Design",
              name: "Motion Design 1",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 2,
              category: "Motion Design",
              name: "Motion Design 2",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 3,
              category: "Motion Design",
              name: "Motion Design 1",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 4,
              category: "Motion Design",
              name: "Motion Design 2",
              date: "2021-2022",
              desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
              image: [
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              },
              {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },                    
            ],
                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            }
          ]
        })
      }
      else if ((e.target as Element).classList.contains('Médias-sociaux')) {
        console.log('Médias');
  
        setData(
          {
            category: "Médias sociaux", 
            data: [
              {
                id: 1,
                category: "Médias sociaux",
                name: "Médias sociaux 1",
                date: "2021-2022",
                desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                image: [
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },                    
              ],
                    behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
              },
              {
                id: 2,
                category: "Médias sociaux",
                name: "Médias sociaux 2",
                date: "2021-2022",
                desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                image: [
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },                    
              ],
                    behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
              },
              {
                id: 3,
                category: "Médias sociaux",
                name: "Médias sociaux 1",
                date: "2021-2022",
                desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                image: [
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },                    
              ],
                    behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
              },
              {
                id: 4,
                category: "Médias sociaux",
                name: "Médias sociaux 2",
                date: "2021-2022",
                desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                image: [
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },                    
              ],
                    behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
              },
              {
                id: 5,
                category: "Médias sociaux",
                name: "Médias sociaux 1",
                date: "2021-2022",
                desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                image: [
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                },
                {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },                    
              ],
                    behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
              },
            ]
          })
        }
        else if ((e.target as Element).classList.contains('Print')) {
          console.log('Print');
    
          setData(
            {
              category: "Print", 
              data: [
                {
                  id: 1,
                  category: "Print",
                  name: "Rapports & magazines 1",
                  date: "2021-2022",
                  desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                  image: [
                    {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },
                        {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },                    
                ],
                        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                },
                {
                  id: 2,
                  category: "Print",
                  name: "Rapports & magazines 2",
                  date: "2021-2022",
                  desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                  image: [
                    {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },
                        {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },                    
                ],
                        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                },
                {
                  id: 3,
                  category: "Print",
                  name: "Rapports & magazines 1",
                  date: "2021-2022",
                  desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                  image: [
                    {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },
                        {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },                    
                ],
                        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                },
                {
                  id: 4,
                  category: "Print",
                  name: "Rapports & magazines 1",
                  date: "2021-2022",
                  desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                  image: [
                    {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                  },
                  {
                    image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    },
                    {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },
                        {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },                    
                ],
                        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                },
              ]
            })
          }  
            else if ((e.target as Element).classList.contains('Web')) {
              console.log('Web');
        
              setData(
                {
                  category: "Web", 
                  data: [
                    {
                      id: 1,
                      category: "Web",
                      name: "Sites internet 1",
                      date: "2021-2022",
                      desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                      image: [
                        {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },
                        {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },                    
                    ],
                                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                    },
                    {
                      id: 2,
                      category: "Web",
                      name: "Sites internet 2",
                      date: "2021-2022",
                      desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                      image: [
                        {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },
                        {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },                    
                    ],
                                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                    },
                    {
                      id: 3,
                      category: "Web",
                      name: "Sites internet 1",
                      date: "2021-2022",
                      desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                      image: [
                        {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                      image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                      },
                      {
                        image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                        },
                        {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },                    
                    ],
                                behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                    },
                  ]
                })
              } 
              else if ((e.target as Element).classList.contains('Tous')) {
                console.log('Tous');
                  setData(
                    {
                      category:  "Tous", 
                      data: [
                        {
            
                          id: 1,
                          category: "Stratégie Tous",
                          name: "Stratégie 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                                        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 2,
                          category: "Stratégie Tous",
                          name: "Stratégie 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                                        behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
            
                          id: 3,
                          category: "Test Tous",
                          name: "Stratégie 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 4,
                          category: "Web Tous",
                          name: "Test",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 5,
                          category: "Design Graphique Tous",
                          name: "Identité Graphique 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 6,
                          category: "Design Graphique Tous",
                          name: "Identité Graphique 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 7,
                          category: "Design Graphique Tous",
                          name: "Identité Graphique 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 8,
                          category: "Design Graphique Tous",
                          name: "Identité Graphique 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 9,
                          category: "Design Graphique Tous",
                          name: "Identité Graphique 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 10,
                          category: "Design Graphique Tous",
                          name: "Identité Graphique 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 15,
                          category: "Motion Design Tous",
                          name: "Motion Design 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 16,
                          category: "Motion Design Tous",
                          name: "Motion Design 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 17,
                          category: "Motion Design Tous",
                          name: "Motion Design 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {                        
                          id: 18,
                          category: "Médias sociaux Tous",
                          name: "Médias sociaux 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 19,
                          category: "Médias sociaux Tous",
                          name: "Médias sociaux 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 21,
                          category: "Médias sociaux Tous",
                          name: "Médias sociaux 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 22,
                          category: "Médias sociaux Tous",
                          name: "Médias sociaux 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 23,
                          category: "Print Tous",
                          name: "Rapports & magazines 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 24,
                          category: "Print Tous",
                          name: "Rapports & magazines 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',

                        },
                        {
                          id: 25,
                          category: "Print Tous",
                          name: "Rapports & magazines 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 28,
                          category: "Web Tous",
                          name: "Sites internet 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 29,
                          category: "Web Tous",
                          name: "Sites internet 2",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                        {
                          id: 3,
                          category: "Web",
                          name: "Sites internet 1",
                          date: "2021-2022",
                          desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, tempore voluptatem eaque officia soluta ad! Officiis fugit sapiente assumenda repellat alias cumque! Dolore ipsa inventore earum placeat deserunt vel. Assumenda",
                          image: [
                            {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                          image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                          },
                          {
                            image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                            },
                            {
                              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                              },
                              {
                                image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                },
                                {
                                  image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                                  },                    
                        ],
                          behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
                        },
                      ]
                    })
              }        
    else {
      setData(null)
    }
  }

  return (
    <div className="expertise-container page-body">
      <div className='expertise-header'>
        <h1 className='animated'>Services</h1>
      </div>
      <div className= "expertise-body"  onClick={() => { if (isOpen) setIsOpen(true)}}>
        <Product product={undefined} togglePopup={togglePopup}  props={undefined} />
        {isOpen && data && <PopUp data={data}
          product={<>
          </>}
        />
          }
      </div>
      <div>
        {/* {allProjects &&
        allProjects.map((projectsList:any) => 
        <div key={projectsList.id} className="project-card">  */}
        {/* <PopUp data={undefined} product={undefined} /> */}
        {/* <p>{projectsList.name} </p>     
          <img className='project-img' src={projectsList.image[0].image} alt={projectsList.name} />
        </div>
       )} */}
      </div>

    </div>
  );
};


export default ProductsPage