import { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./Pages/Contact/ContactPage";
import Home from "./Pages/home/HomePage";
import Team from "./Pages/team/TeamPage";
import Expertise from "./Pages/expertise/ExpertisePage";
import Product from "./Pages/product/ProductsPage";
import Headers from "./Components/header/Headers";
import Footer from "./Components/footer/Footer";
import Error404 from "./Pages/Error404";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { UseAnalytics } from "./useAnalytics";
import { Wrapper } from "./Components/wrapperGa/WrapperGa";
import { Helmet } from "react-helmet";
import CardDetails from "./Components/projects/cards/CardDetails";
import ReactGA from "react-ga4";
import LegalNotices from "./Pages/legal-notices/LegalNotices";
import ProtectionPolicy from "./Pages/protection policy/protectionPolicy";
// import CardDetails from './Components/projects/cards/CardDetails';
// import CardDetails from './Components/projects/cards/CardDetails';

function App() {
  const TRACKING_ID = "UA-92985139-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  ////// GA 4 ///////

  const MEASUREMENT_ID = "G-FCW9WC56D6";
  ReactGA.initialize(MEASUREMENT_ID);
  ReactGA.send("pageview");
  ReactGA.send({
    hitType: "pageview",
    page: "/window.location.pathname + window.location.search",
    title: "window.location.pathname",
  });
  ReactGA.send({ hitType: "pageview", page: "/expertise", title: "Expertise" });
  ReactGA.send({ hitType: "pageview", page: "/equipe", title: "Equipe" });
  ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact" });

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  // useEffect(() => {
  //   ReactGA.pageview("/expertise");
  // }, []);

  // useEffect(() => {
  //   ReactGA.pageview("/equipe");
  // }, []);

  // useEffect(() => {
  //   ReactGA.pageview("/contact");
  // }, []);

  let isInitialized = false;
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      //get the id
      const id = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
      // console.log(id);

      const ga = UseAnalytics(id);
      //get the result
      isInitialized = ga.initialized;
      // console.log(isInitialized);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue("GA-CookieConsent");
    if (isConsent === "true") {
      handleAcceptCookie();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Helmet>
          <title>Polydea</title>
          <meta name="description" content="Polydea" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <Wrapper initialized={isInitialized}>
          <Headers />
          <main className="container">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/polydea" element={<Home />} />
              <Route path="/equipe" element={<Team />} />
              <Route path="/expertise" element={<Expertise />} />
              <Route path="/services" element={<Product />} />
              <Route path="/*" element={<Error404 />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/mentions-legales" element={<LegalNotices />} />
              <Route
                path="/politique-protection-des-donnees"
                element={<ProtectionPolicy />}
              />
              <Route
                path="/projet/:id"
                element={<CardDetails project={undefined} />}
              />
            </Routes>
          </main>
          <Footer />
          <CookieConsent
            location="bottom"
            cookieName="GA-CookieConsent"
            enableDeclineButton
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
            style={{ background: "#A12899" }}
            buttonStyle={{
              color: "#fff",
              background: "#000000",
              fontSize: "13px",
            }}
            expires={150}
          >
            <p className="cookieText">
              This website uses cookies to enhance the user experience.{" "}
            </p>
          </CookieConsent>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
