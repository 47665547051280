import React, { useState } from "react";
import ProjectList from "../projects/list/ProjectList";
import "./PopUp.css";

const PopUp = ({ data, product }: { data: any; product: any }) => {
  console.log(data);

  return (
    <div className="PopUp">
      <div className="popup-container">
          {/* <h2
            className='popup-title'
          >
            {data.text}
          </h2> */}
        <div className="popup-body">
          <ProjectList data={data.data} product={product} />
        </div>
        <div className="load-container">
          <div className="link-container">
          <a
          href="https://www.behance.net/elsaberry"
          target="_blank"
          rel="noreferrer"
          className="load-text"
        >
          Voir + de projets
        </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
