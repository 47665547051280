import React, { useState } from 'react';
import Expertise from '../../Components/expertise/Expertise';
import PopUp from '../../Components/popup/PopUp';
import Pev from '../../assets/pictures/pev.png';
import './ExpertisePage.css';
import {Helmet} from "react-helmet";

const ExpertisePage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<iData | null>(null);

  interface iData {
    category: string,
    data: Array<iArray>
  }

  interface iArray {
    id: number,
    category: string,
    name: string,
    date: string,
    image: string,
    behance: any,
  }


  const togglePopup = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

    setIsOpen(!isOpen);

    if ((e.target as Element).classList.contains('Graphic-Design')) {
      console.log('Graphic-Design');

      setData(
        {
          category: "Graphic Design",
          data: [
            {
              id: 1,
              category: "Graphic Design",
              name: "Project design 1",
              date: "2021-2022",
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 2,
              category: "Graphic Design",
              name: "Project design 2",
              date: "2021-2022",
              image: "https://images.unsplash.com/photo-1658242356534-9935f4e9aaed?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            }
          ]
        })

    }

    else if ((e.target as Element).classList.contains('Web-development')) {
      console.log('web');

      setData(
        {
          category: "Web development", data: [
            {

              id: 1,
              category: "Web development",
              name: "Project web 1",
              date: "2021-2022",
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 2,
              category: "Web development",
              name: "Project web 2",
              date: "2021-2022",
              image: "https://images.unsplash.com/photo-1658242356534-9935f4e9aaed?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            }]
        })
    }

    else if ((e.target as Element).classList.contains('Digital-development')) {
      console.log('dgd');

      setData(
        {
          category: "Digital development", data: [
            {
              id: 1,
              category: "Digital development",
              name: "Project development 1",
              date: "2021-2022",
              image: "https://images.unsplash.com/photo-1658784566751-95382f065d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 2,
              category: "Digital development",
              name: "Project development 2",
              date: "2021-2022",
              image: "https://images.unsplash.com/photo-1658242356534-9935f4e9aaed?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            }
          ]
        })

    }
    else if ((e.target as Element).classList.contains('Strategy-&-Identity')) {
      console.log('strat');

      setData(
        {
          category: "Strategy & Identity", data: [
            {
              id: 1,
              category: "Strategy & Identity",
              name: "Prevent Violent Extremism - EU",
              date: "2021-2022",
              image: require("../../assets/pictures/pev.png"),
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            },
            {
              id: 2,
              category: "Strategy & Identity",
              name: "Annual Cooperation Report - Ministry of Finance (Ethiopia)",
              date: "2021-2022",
              image: require("../../assets/pictures/ethio.png"),
              behance: 'https://www.behance.net/gallery/148662129/Ministry-of-Finance-Annual-Cooperation-report',
            }
          ]
        })
    }
    else {
      setData(null)
    }
  }

  return (
    
    <div className="expertise-container page-body">
       <Helmet>
                <meta charSet="utf-8" />
                <title>Polydea - Expertise</title>
                <meta name="description" content=" Nos services : le conseil stratégique, le développement de marque et la création graphique"/>
                <link rel="canonical" href="https://www.polydea.com/expertise" />
      </Helmet>
      <div className='expertise-header'>
        <h1 className='animated'>Expertise</h1>
        <div className='expertise-intro'>
        </div>
      </div>
      <div className="expertise-body" >
        <Expertise expertise={undefined} togglePopup={undefined} />
        {/* {isOpen && data  && <PopUp data={data}
          expertise={<>
          </>}
        />} */}
      </div>
    </div>
  );
};

export default ExpertisePage
