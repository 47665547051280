import React from "react";
import { useTranslation } from "react-i18next";
import './protectionPolicy.css'

const protectionPolicy = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation(["translation"]);

  return (
    <div className="protectionPolicyContainer">
      <h1>{t("protectionPolicyTitle", { ns: ["translation"] })}</h1>
      <article>
        <h3>{t("protectionIntroTitle", { ns: ["translation"] })} </h3>
        <p>{t("protectionIntro", { ns: ["translation"] })}</p>
      </article>
      <article>
        <h3>{t("personalDataTitle", { ns: ["translation"] })} </h3>
        <p>
          {t("personalDataList", { ns: ["translation"] })}
          <ul>
            <li>
              {t("personalDataFirstPoint", { ns: ["translation"] })} <br />
              {t("personalDataText1", { ns: ["translation"] })}
            </li>
            <li>
              {t("personalDataSecondPoint", { ns: ["translation"] })} <br />
              {t("personalDataText2", { ns: ["translation"] })}
            </li>
          </ul>
        </p>
      </article>
      <article>
        <h3>{t("dataUseTitle", { ns: ["translation"] })} </h3>
        <p>{t("dataUseText", { ns: ["translation"] })}</p>
      </article>
      <article>
        <h3>{t("legalBaseTitle", { ns: ["translation"] })} </h3>
        <p>{t("legalBaseText", { ns: ["translation"] })}</p>
      </article>
      <article>
        <h3>{t("retentionPeriodTitle", { ns: ["translation"] })} </h3>
        <p>{t("retentionPeriodText", { ns: ["translation"] })}</p>
      </article>
      <article>
        <h3>{t("cookiesTitle", { ns: ["translation"] })} </h3>
        <p>
          {t("cookiesList", { ns: ["translation"] })}
          <ul>
            <li>
              {t("cookiesFirstPoint", { ns: ["translation"] })}
              <a
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("exhaustiveList", { ns: ["translation"] })}
              </a>

              {t("cookiesFirstPointWA", { ns: ["translation"] })}
            </li>
            <li>{t("cookiesSecondPoint", { ns: ["translation"] })}</li>
          </ul>
        </p>
      </article>
      <article>
        <h3>{t("userRightsTitle", { ns: ["translation"] })} </h3>
        <p>{t("userRightsText", { ns: ["translation"] })}</p>
      </article>
      <article>
        <h3>{t("contactTitle", { ns: ["translation"] })} </h3>
        <p>{t("contactText", { ns: ["translation"] })}</p>
      </article>
    </div>
  );
};

export default protectionPolicy;
