import React from "react";
import Team from "../../Components/team/Team";
import "./TeamPage.css";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const TeamPage: React.FC = () => {

  const { t } = useTranslation(['translation']);

  return (
    <div className="team-container page-body">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Polydea - Equipe</title>
        <meta name="description" content="Fondée en 2012, Polydea est une agence de communication spécialisée
            dans la coopération internationale. Elle travaille principalement
            pour des projets de développement économique, d'appui à la
            gouvernance, de protection de l'environnement et de développement
            durable. Elle est dirigée par Elsa Berry" />
        <link rel="canonical" href="https://www.polydea.com/equipe" />
      </Helmet>
      <h1 className="team-title animated">
      {t('teamTitle', {ns: ['translation']})}
      </h1>
      <div className="team-body">
        <div className="team-text">
          <p className="team-first-paragraph">
          {t('teamText1', {ns: ['translation']})}
            <a
              href="https://www.linkedin.com/in/berryelsa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Elsa Berry
            </a>
            .
          </p>

          <p className="team-second-paragraph">
          {t('teamText2', {ns: ['translation']})}
          </p>
        </div>
        <Team />
      </div>
    </div>
  );
};

export default TeamPage;
